import React, { memo } from 'react';

import { ABOUT_ACCESS } from '../../constants';
import { NavItem, SubMenu, SubTitle, TopMenuItem } from './components';

const NavAbout = memo(props => {
  const { isLoggedIn, ..._props } = props;
  return (
    <TopMenuItem {..._props} title={<span>About</span>} className="hideIfClosed">
      <NavItem type="default" path="/about" locked={false} />
      <SubMenu title={<SubTitle title="Membership" locked={false} />}>
        <NavItem
          type="subItem"
          path="/about/membership/member-resources"
          memberLevels={ABOUT_ACCESS.MEMBERSHIP}
        />
        <NavItem type="subItem" path="/about/membership/join-a-committee" locked={false} />
        <NavItem type="subItem" path="/about/membership/member-relations-team" locked={false} />
      </SubMenu>
      <NavItem title="Join APCIA" type="default" path="/about/why-join-apcia" locked={false} />
      {isLoggedIn ? null : <NavItem type="default" path="/about/contact-us" locked={false} />}
      <SubMenu title={<SubTitle title="Leadership" locked={false} />}>
        <NavItem
          title="David A. Sampson, CEO"
          path="/about/leadership/senior-staff/75991"
          as="/about/leadership/senior-staff/75991"
          locked={false}
          type="subItem"
        />
        <NavItem
          path="/about/leadership/ceo-communications"
          memberLevels={ABOUT_ACCESS.CEO_COMMUNICATIONS}
        />
        <NavItem
          path="/about/leadership/board-of-directors"
          memberLevels={ABOUT_ACCESS.BOARD_DIRECTORS}
        />
        <NavItem path="/about/leadership/senior-staff" locked={false} />
      </SubMenu>
      {isLoggedIn ? <NavItem type="default" path="/about/ask-us" /> : null}
      <NavItem
        type="default"
        path="/about/staff-directory"
        memberLevels={ABOUT_ACCESS.STAFF_DIRECTORY}
      />
      <NavItem type="default" path="/about/apcia-careers" locked={false} />
    </TopMenuItem>
  );
});

export default NavAbout;
